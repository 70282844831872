/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/** Load custom Vue components * */
require('./components/cookie-message');
require('./components/home-intro');
require('./components/page-intro-visual-right');
require('./components/page-intro-bubble-downloads');
require('./components/page-intro-bubble-contact');
require('./components/page-intro-team');
require('./components/square-slider');
require('./components/footer');
require('./components/appointment-form');
require('./components/PoppyPopup');


/**
 * We load the Greensock libraries in the application scope, since
 * the bootstrap scope is not working for these includes.
 */

import { TweenMax, TimelineMax } from 'gsap/all';
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

window.App = new Vue({
    el: '#app',
    delimiters: ['${', '}'],

    data() {
        return {
            showMenu: false,
            showFormPopup: true,
        };
    },

    components: {

    },

    mounted() {
        // Check for window resize
        window.addEventListener('resize', this.onWindowResize);

        // Add inverted menu after scroll, if tablet portrait or larger
        this.headerController = new ScrollMagic.Controller();

        const headerTimeline = new TimelineMax();

        headerTimeline.to('.header__bg', 0.5, { y: '0%', delay: 0.2 })

        const headerAnimation = new ScrollMagic.Scene({ offset: '20' })
            .setClassToggle('header', 'sticky-header')
            .setTween(headerTimeline);

        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth > 767) {
            this.headerController.addScene(headerAnimation);
        }

        // Initialize the page scroll controller for the scroll reveal animations
        this.initScrollReveals();

        this.alert();
    },

    methods: {
        /**
         * Toggles the parent element active state of the element which raised this event.
         *
         * @param {Event} event
         */
        toggleParentElement(event) {
            const parent = event.currentTarget.parentElement;
            if (!parent) {
                return;
            }

            parent.classList.toggle('active');
        },
        
        initPage() {
           
        },

        /**
         * Toggle the menu
         * 
         */
        toggleMenu() {
            this.showMenu = !this.showMenu;
            document.body.classList.toggle('navigation-open');
        },

        /**
         * Toggle the appointment form
         * 
         */
        toggleAppointmentForm() {
            EventBus.$emit('toggle-appointment-form');
        },

        /**
         * Close the appointment form
         * 
         */
        closeAppointmentForm() {
            EventBus.$emit('close-appointment-form');
        },

        /**
         * Close the appointment form
         * 
         */
        closeFormPopup() {
            this.showFormPopup = false;
        },

        /**
         * Initializes scrollmagic for the scroll reveals
         *
         * @returns {Void}
         */
        initScrollReveals() {
            this.pageScrollController = new ScrollMagic.Controller();

            // Initialize the default reveal elements.
            const revealElements = [].slice.call(document.querySelectorAll('.scroll-reveal'));
            
            revealElements.forEach((revealElement) => {
                const element = revealElement;
                const triggerHook = 0.9;

                const scene = new ScrollMagic.Scene({
                    triggerElement: element, triggerHook
                })
                .reverse(true)
                .addTo(this.pageScrollController);

                scene.on('enter', (event) => {
                    TweenMax.to(element, .6, { y: 0, autoAlpha: 1 });
                });
            });
        },

        getCookie(name) {
            var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return v ? v[2] : null;
        },

        alert() {
            if(this.getCookie("notification-popup")){
                return;
            };
            const d = new Date();
            d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString();
            document.cookie = 'notification-popup' + '=' + 1 + '; ' + 30;
            const wrapper = document.createElement('div');
            wrapper.innerHTML = "<p>Wir modernisieren die Praxis vom <strong>04.03.2021 bis zum 9.04.2021</strong>.</p><p>Für Sie bedeutet das: leicht veränderte Öffnungszeiten (ein paar Tage geschlossen) und „Handwerkergeräuche“.</p>";
            this.$swal({
                title: "Liebe PatientInnen,",
                content: wrapper,
                button: "Einverstanden"
            })
          },
    },

    beforeDestroy() {
        // Remove listeners
        // EventBus.$off('page-transition-enter');
        // EventBus.$off('page-transition-leave');
    },
});

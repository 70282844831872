import Glide from '@glidejs/glide';

Vue.component('square-slider', {

    data() {
        return {
            sliderActive: 0,
        };
    },

    mounted() {
        const slider = new Glide(this.$refs.slider, {
            type: 'carousel',
            gap: 0,
            autoplay: 4500,
            animationDuration: 800,
        });

        this.sliderActive = slider.index + 1;

        slider.mount();

        slider.on('move', () => {
           this.sliderActive = slider.index + 1;
        })
    },

    methods: {

    },
});

Vue.component('page-intro-bubble-downloads', {

    data() {
        return {
            
        };
    },

    mounted() {

         // Reveal animation of page intro
         const pageTL = new TimelineMax({
            paused: true,
            delay: 0.2
        });

        pageTL.to('.page-intro-bubble-bubble', 1.8, {y: '0', autoAlpha: 1, ease: Power3.easeOut });
        pageTL.to('.page-intro-bubble-title', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.3);
        pageTL.to('.page-intro-bubble-text', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.4);
        pageTL.staggerTo(".download__item", 1, { autoAlpha: 1, y: 0 }, 0.13, 0.3);

        pageTL.play();
    },

    methods: {

    },
});

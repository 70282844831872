Vue.component('page-intro-team', {

    data() {
        return {
        };
    },

    mounted() {

         // Reveal animation of page intro
         const pageTL = new TimelineMax({
            paused: true,
            delay: 0.2
        });

        pageTL.to('.page-intro-right-image', 1.8, {x: '0', autoAlpha: 1, ease: Power3.easeOut });
        pageTL.to('.page-intro-right-title', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.3);
        pageTL.to('.page-intro-right-text', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.4);
        pageTL.to('.page-intro-right-breadcrumb', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.8);
        pageTL.to('.page-intro-right-biography', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 1);

        pageTL.play();
    },

    methods: {

    },
});

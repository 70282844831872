import Glide from '@glidejs/glide';

Vue.component('page-intro-visual-right', {

    data() {
        return {
            sliderActive: 0,
        };
    },

    mounted() {

         // Reveal animation of page intro
         const pageTL = new TimelineMax({
            paused: true,
            delay: 0.2
        });

        pageTL.to('.page-intro-right-image', 1.8, {x: '0', autoAlpha: 1, ease: Power3.easeOut });
        pageTL.to('.page-intro-right-title', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.3);
        pageTL.to('.page-intro-right-text', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.4);

        pageTL.play();

        const slider = new Glide(this.$refs.slider, {
            type: 'carousel',
            gap: 0,
            autoplay: 4500,
            animationDuration: 800,
        });

        this.sliderActive = slider.index + 1;

        slider.mount();

        slider.on('move', () => {
           this.sliderActive = slider.index + 1;
        })
    },

    methods: {

    },
});

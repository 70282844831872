import { TweenMax } from "gsap";

Vue.component('home-intro', {

    data() {
        return {
            
        };
    },

    mounted() {
        // Reveal animation of home intro
        const homeTl = new TimelineMax({
            paused: true,
            delay: 0.2,
        });

        homeTl.to('.home-hero__curtain', 1.2, {y: '100%', ease: Power3.easeOut });
        homeTl.to('#home-hero .image-wrapper', 1.2, { y: 0, ease: Power3.easeOut }, 0);
        homeTl.to('.intro-reveal-1', 1.5, { x: 0, autoAlpha: 1, ease: Power3.easeOut }, 0.5);
        homeTl.to('.intro-reveal-2', 1.5, { y: 0, autoAlpha: 1, ease: Power3.easeOut }, 1);
        homeTl.staggerTo(".home-contact-reveal", 1, { autoAlpha: 1, y: 0 }, 0.15, 1.3);

        homeTl.play();
    },

    methods: {
        /**
         * Toggle the appointment form
         * 
         */
        toggleAppointmentForm() {
            EventBus.$emit('toggle-appointment-form');
        },

        arrowLeftEnter() {
            TweenMax.to('.arrow-bubble--left', 2, {x: '-75%', ease: Power3.easeOut });
        },

        arrowLeftLeave() {
            TweenMax.to('.arrow-bubble--left', 1, {x: '-100%', ease: Power3.easeOut });
        },

        arrowRightEnter() {
            TweenMax.to('.arrow-bubble--right', 2, {x: '75%', ease: Power3.easeOut });
        },

        arrowRightLeave() {
            TweenMax.to('.arrow-bubble--right', 1, {x: '100%', ease: Power3.easeOut });
        },
    },
});

Vue.component('cookie-message', {

    data() {
        return {
            showCookieMessage: false,
        };
    },

    mounted() {
        const overlay = this.getCookie('cookie-message');
        if (overlay != 1) {
            this.showCookieMessage = true;
            setTimeout(() => {
                document.getElementById('cookie').classList.add('visible');
            }, 500);
        }
    },

    methods: {
        /**
 * Sets overlay cookie and hides the overlay
 *
 * @return {Void}
 */
        clickOverlay() {
            this.setCookie('cookie-message', 1, 45);
            this.showCookieMessage = false;
        },

        /**
         * Sets a given cookie
         *
         * @param {String} cname
         * @param {Number} cvalue
         * @param {Number} exdays
         */
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + '; ' + expires;
        },

        /**
         * Gets a certain cookie
         *
         * @param  {String} cname
         * @return {String}
         */
        getCookie(cname) {
            const name = cname + '=';
            if (!document.cookie) {
                return '';
            }
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        },
    },
});

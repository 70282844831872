Vue.component('footer-component', {

    data() {
        return {
            
        };
    },

    mounted() {
        // Reveal animation of page intro
        const footerTL = new TimelineMax({
            paused: true,
            delay: 1
        });

        footerTL.to('.footer-reveal', 1.8, {y: '0', autoAlpha: 1, ease: Power3.easeOut });

        footerTL.play();
    },

    methods: {

    },
});

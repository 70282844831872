Vue.component('appointment-form', {
    props: {
        error: Boolean,
    },

    data() {
        return {
            showAppointmentForm: false,
        };
    },

    mounted() {
        if(this.error) {
            this.toggleAppointmentForm();
        }

        EventBus.$on('toggle-appointment-form', () => {
            this.toggleAppointmentForm();
        });

        EventBus.$on('close-appointment-form', () => {
            this.closeAppointmentForm();
        });
    },

    methods: {
        /**
         * Toggle the appointment form
         * 
         */
        toggleAppointmentForm() {
            this.showAppointmentForm = !this.showAppointmentForm;
            document.body.classList.toggle('opened-appointment-form');
        },

        /**
         * Close the appointment form
         * 
         */
        closeAppointmentForm() {
            this.showAppointmentForm = false;
            document.body.classList.remove('opened-appointment-form');
        },
    },
});

Vue.component('page-intro-bubble-contact', {

    data() {
        return {
        };
    },

    mounted() {

         // Reveal animation of page intro
         const pageTL = new TimelineMax({
            paused: true,
            delay: 0.2
        });

        pageTL.to('.page-intro-bubble-bubble', 1.8, {y: '0', autoAlpha: 1, ease: Power3.easeOut });
        pageTL.to('.page-intro-stagger', 1.5, {y: '0', autoAlpha: 1, ease: Power3.easeOut }, 0.3);

        pageTL.play();
    },

    methods: {
        /**
         * Toggle the appointment form
         * 
         */
        toggleAppointmentForm() {
            EventBus.$emit('toggle-appointment-form');
        },
    },
});
